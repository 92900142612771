import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentPage from '~/components/Content/Page'
import { setAffiliateCode } from '~/utils/affiliates'
import withLocation from "~/hoc/withLocation"

const StaticPageTemplate = ({ data, search }) => {
  setAffiliateCode(search)  
  const { page } = data

  return (
    <Layout>
      <Metadata title={page.name} />
      <ContentPage page={page} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query StaticPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`

export default withLocation(StaticPageTemplate)
